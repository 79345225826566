<template>
  <asom-card>
    <div class="space-y-4">
      <!-- <div class="md:w-1/2">
        <asom-input-select
          v-model="source"
          :options="sourceOptions"
        />
      </div> -->
      <section
        class="cash-order-grid grid card shadow rounded w-full overflow-scroll"
        :style="{ '--total-columns': allDates.length + 4 }"
      >
        <!-- header -->
        <aside
          class="cash-order-grid_header row-span-1 col-span-1 flex items-center justify-center border-b border-gray-300"
          :style="{ '--left-sticky-columns': 0, top: 0, 'z-index': 21 }"
        >
          No
        </aside>
        <aside
          class="cash-order-grid_header row-span-1 col-span-2 flex items-center border-b border-r border-gray-300"
          :style="{ '--left-sticky-columns': 1, top: 0, 'z-index': 21 }"
        >
          Station
        </aside>

        <template v-for="(day, i) in allDates" :key="`day_${i}`">
          <div
            class="cash-order-grid_cell cash-order-grid_day border-r border-l border-b border-gray-300"
            :style="{ '--top-sticky-columns': 0 }"
          >
            <span class="dayName">{{ displayUtcDate(day) }}</span>
          </div>
        </template>
        <div
          class="column-total cash-order-grid_cell cash-order-grid_day border-r border-l border-b border-gray-300"
          :style="{ '--top-sticky-columns': 0 }"
        >
          <span class="dayName">Total</span>
        </div>
        <!-- end header -->
        <!-- body -->
        <template
          v-for="(station, sIndex) in displayData"
          :key="station.stationId"
        >
          <aside
            class="AsomCashOrderList--indexNo cash-order-grid_row col-span-1 flex items-center justify-center bg-gray-50 border-b border-gray-300"
            :class="[sIndex % 2 ? 'odd' : 'even']"
            :style="{ '--left-sticky-columns': 0 }"
          >
            {{ sIndex + 1 }}
          </aside>

          <aside
            class="AsomCashOrderList--Cell cash-order-grid_row col-span-2 flex items-center  border-r border-gray-300 border-b border-gray-300"
            :class="[sIndex % 2 ? 'odd' : 'even']"
            :style="{ '--left-sticky-columns': 1 }"
          >
            {{ station.abbreviation }}
          </aside>

          <template
            v-for="(day, i) in allDates"
            :key="`station_${sIndex}_day_${i}`"
          >
            <div
              class="AsomCashOrderList__Body--InnerGrid--Cell cash-order-grid_cell cash-order-grid_row border-r border-l border-b border-gray-300 relative"
              :class="[sIndex % 2 ? 'odd' : 'even']"
            >
              {{ amountOfDate(station.dailyReceipts, day) }}
            </div>
          </template>
          <div
            class="column-total AsomCashOrderList__Body--InnerGrid--Cell cash-order-grid_cell cash-order-grid_row border-r border-l border-b border-gray-300 relative"
            :class="[sIndex % 2 ? 'odd' : 'even']"
          >
            {{ total(station.dailyReceipts) }}
          </div>
        </template>
        <!-- end body -->
      </section>
    </div>
  </asom-card>
</template>
<script>
import get from "lodash.get";
import { toMoment, displayUtcDate } from "@/helpers/dateTimeHelpers";
import { formatCurrency } from "@/helpers/numbers";
import moment from "moment";

export default {
  props: ["tableData", "source"],
  computed: {
    sourceOptions() {
      return [
        { value: "manualChange", label: "Manual Change" },
        { value: "coinTube", label: "Coin Tube" },
        { value: "psm", label: "PSM" },
        { value: "others", label: "Others" },
      ];
    },
    allDates() {
      let result = [];
      let start = moment.utc(get(this.tableData, "startDate"));
      let end = moment.utc(get(this.tableData, "endDate"));
      if (!start.isValid() || !end.isValid()) return result;
      while (start.isBefore(end)) {
        result.push(displayUtcDate(start));
        start.add(1, "days");
      }
      return result;
    },
    displayData() {
      if (!this.source) return [];
      return this.tableData[this.source] || [];
    },
  },
  methods: {
    displayUtcDate(date) {
      const m = toMoment(date);
      return m.isValid() ? m.format("DD/MM") : "";
    },
    amountOfDate(data = [], date) {
      let amount = 0;
      data.forEach((item) => {
        let itemDate = displayUtcDate(moment.utc(item.date));
        if(itemDate == date) {
          amount += get(item, "amount", 0);
        }
      });
      return amount;
    },
    total(data = []) {
      let sum = 0;
      data.forEach((receipt) => (sum += receipt.amount));
      return formatCurrency(sum);
    },
  },
};
</script>

<style lang="scss" scoped>
.weekStyle {
  @apply text-gray-400;
}
.cash-order-grid {
  --total-columns: 0;

  grid-template-columns: repeat(var(--total-columns), theme("height.12"));
  grid-auto-rows: theme("height.12");
  will-change: transform;
  position: relative;
  max-height: 400px;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    z-index: -1;
  }

  &_cell {
    width: theme("width.12");
  }

  &_day,
  &_date {
    --top-sticky-columns: 0;

    position: sticky;
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -ms-sticky;
    position: -o-sticky;

    top: calc(theme("width.12") * var(--top-sticky-columns));
    z-index: 20;
    @apply tracking-wider;
  }

  &_cell,
  &_day,
  &_date {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &_day {
    .dayName {
      font-size: 0.65rem;
      @apply text-gray-400 tracking-wider;
    }

    .dateNo {
      font-size: 0.9rem;
      @apply text-gray-700;
    }

    flex-direction: column;
    @apply bg-gray-100;
  }

  &_header {
    --left-sticky-columns: 0;

    position: sticky;
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -ms-sticky;
    position: -o-sticky;

    left: calc(theme("width.12") * var(--left-sticky-columns));
    @apply tracking-wider;
  }
}

.cash-order-grid_header {
  @apply bg-gray-50 text-xs uppercase text-gray-600 font-semibold;
}

.AsomCashOrderList--Cell,
.AsomCashOrderList--indexNo {
  left: calc(theme("width.12") * var(--left-sticky-columns));
  @apply text-sm sticky;
  z-index: 11;
}

.cash-order-grid_day,
.cash-order-grid_date {
  @apply text-xs  font-semibold uppercase;
}

.AsomCashOrderList--Cell {
  @apply text-black font-semibold;
}

.cash-order-grid_header.odd,
.cash-order-grid_cell.odd,
.AsomCashOrderList--indexNo.odd,
.AsomCashOrderList--Cell.odd {
  @apply bg-gray-50;
}

.cash-order-grid_header.even,
.cash-order-grid_cell.even,
.AsomCashOrderList--indexNo.even,
.AsomCashOrderList--Cell.even {
  @apply bg-white;
}

.AsomCashOrderList__Body--InnerGrid--Cell {
  @apply font-semibold cursor-pointer text-xs tracking-wider;
}
.AsomCashOrderList__Body--InnerGrid--Cell.off {
  @apply text-gray-800;
}
.AsomCashOrderList__Body--InnerGrid--Cell.rest {
  @apply text-gray-500;
}
.AsomCashOrderList__Body--InnerGrid--Cell.spr {
  @apply text-gray-600;
}

.cash-order-grid_cell {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none;
}
.column-total{
  width: 6rem;
}
</style>
