<template>
  <div class="space-y-4">
    <asom-card title="Filter">
      <asom-grid :sm="1" :md="2">
        <asom-form-field label="Date" required :state="inputStates('filters.dateRange')" error="Date range is required">
          <asom-input-date-range v-model="filters.dateRange" :state="inputStates('filters.dateRange')"/>
        </asom-form-field>
        <asom-form-field label="Line" required>
          <asom-input-select
            :disabled="!canSelectLine"
            v-model="filters.line"
            :options="lineOptions"
          />
        </asom-form-field>
        <asom-form-field label="Stations">
          <asom-multi-select
            :objectModelValue="false"
            v-model="filters.stations"
            :options="stationOptions"
          />
        </asom-form-field>
        <asom-form-field label="Cash Order Type">
          <asom-input-select v-model="filters.source" :options="sourceOptions"
        /></asom-form-field>
        <asom-form-field label="Cash Order Status" 
          required 
          :state="inputStates('filters.orderStatus')"
          error="Cash Order Status is required">
          <asom-input-select
            :state="inputStates('filters.orderStatus')"
            :objectModelValue="false"
            v-model="filters.orderStatus"
            :options="statusListOptions"
          />
        </asom-form-field>
      </asom-grid>
      <template #footer>
        <asom-export-to-excel
          :fileName="'Cash Order Report'"
          :fields="exportData.header"
          :labels="exportData.header"
          :data="exportData.stationData"
        />
        <asom-button
          text="Reset Filters"
          :disabled="isLoading"
          variant="secondary"
          @click="resetFilters"
        />
        <asom-button
          text="Apply"
          :disabled="isLoading"
          :loading="isLoading"
          @click="loadData"
        />
      </template>
    </asom-card>
    <div class="w-full text-center" v-if="isLoading">
      <asom-icon icon="spinner" class="animate-spin" />
    </div>
    <asom-alert v-else-if="error" variant="error" :error-message="error" />
    <cash-order-report-table
      v-else
      :tableData="tableData"
      :source="filters.source.value"
    />
  </div>
</template>

<script>
import get from "lodash.get";
import moment from "moment";
import { mapGetters } from "vuex";
import { postAsync } from "@/services/serviceHandlers";
import { GET_CASH_ORDER_REPORT } from "@/constants/apis";
import { parseDateTimeToUtc, parseDateTime, displayUtcDate } from "@/helpers/dateTimeHelpers";
import CashOrderReportTable from "./_CashOrderReportTable.vue";
import { NSEWL } from "../../../constants/values";
import { cashOrder } from "../../../constants/APIEnums/cashManagementEnums";
import inputStates from "@/mixins/inputStates";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  setup: () => ({ v$: useVuelidate() }),
  mixins: [inputStates],
  components: {
    CashOrderReportTable,
  },
  data() {
    return {
      filters: {
        dateRange: {
          start: moment().toDate(),
          end: moment()
            .add(1, "months")
            .toDate(),
        },
        line: this.$store.getters["auth/userLineOption"],
        stations: [],
        source: { value: "manualChange", label: "Manual Change" },
        orderStatus: cashOrder.cashOrderStatus.DELIVERY_FULFILLED.VALUE
      },
      tableData: {
        startDate: null,
        endDate: null,
        manualChange: [],
        coinTube: [],
        psm: [],
        others: [],
      },
      isLoading: false,
      error: null,
      exportData: {
        header: [],
        stationData: []
      }
    };
  },
  mounted() {
    this.loadData();
  },
  computed: {
    ...mapGetters({
      lineOptions: "smrtResource/lineOptions",
      userId: "auth/userId",
      canMarkUnavailability: "auth/canMarkUnavailability",
      canSelectLine: "auth/canSelectLine",
    }),
    stationOptions() {
      // SMCR是ASOMS上的一个电台。 它没有现金功能。 这里的修复仅涉及将它们从现金订单报告站列表 和 Excel 现金订单报告中删除
      var tmpStations = this.$store.getters["smrtResource/stationOptionsByLineId"](
        get(this.filters.line, "value")
      );
      return tmpStations.filter(item => item.name != "SMCR");
    },
    sourceOptions() {
      return [
        { value: "manualChange", label: "Manual Change" },
        { value: "coinTube", label: "Coin Tube" },
      ];
    },
    statusListOptions() {
      return [
        { value: cashOrder.cashOrderStatus.IN_REVIEW.VALUE, label: cashOrder.cashOrderStatus.IN_REVIEW.NAME },
        { value: cashOrder.cashOrderStatus.REJECTED.VALUE, label: cashOrder.cashOrderStatus.REJECTED.NAME },
        { value: cashOrder.cashOrderStatus.PENDING_DELIVERY.VALUE, label: cashOrder.cashOrderStatus.PENDING_DELIVERY.NAME },
        { value: cashOrder.cashOrderStatus.PENDING_PICKUP.VALUE, label: cashOrder.cashOrderStatus.PENDING_PICKUP.NAME },
        { value: cashOrder.cashOrderStatus.DELIVERY_FULFILLED.VALUE, label: cashOrder.cashOrderStatus.DELIVERY_FULFILLED.NAME },
        { value: cashOrder.cashOrderStatus.DELIVERY_REJECTED.VALUE, label: cashOrder.cashOrderStatus.DELIVERY_REJECTED.NAME }
      ];
    },
    columns() {
      const fields = [
        "personNo",
        "officer",
        "role",
        "station",
        "blockedLeaves",
      ];
      const labels = [
        "Staff ID",
        "Officer",
        "Role",
        "Station",
        "Blocked Leaves (days)",
      ];
      const data = [];
      return {
        fields,
        labels,
        data,
      };
    },
  },
  validations() {
      return {
        filters: {
          orderStatus: { required },  
          dateRange: {
            start: { required },
            end: { required }
          }
        }
      }
  },
  methods: {
    resetFilters() {
      this.filters.dateRange = {
        start: moment().toDate(),
        end: moment()
          .add(1, "months")
          .toDate(),
      };
      this.filters.stations = [];
      this.filters.orderStatus = cashOrder.cashOrderStatus.DELIVERY_FULFILLED.VALUE;
    },
    async loadData() {
      this.v$.$reset();
      this.v$.$touch();
      if (!this.v$.filters.$invalid) {
        this.isLoading = true;
        this.error = null;
        const resp = await postAsync({
          url: GET_CASH_ORDER_REPORT,
          body: {
            LineId: get(this.filters.line, "value"),
            StartDate: parseDateTimeToUtc(parseDateTime(this.filters.dateRange.start,false) + " 00:00:00", true),
            EndDate: parseDateTimeToUtc(parseDateTime(this.filters.dateRange.end,false) + " 23:59:59", true),
            StationIds: this.filters.stations,
            source: this.filters.source.value,
            orderStatus: this.filters.orderStatus
          },
        });
        if (resp.success) {
          this.tableData.startDate = get(resp, "payload.data.startDate");
          this.tableData.endDate = get(resp, "payload.data.endDate");
          this.tableData.manualChange = get(resp, "payload.data.manualChange", []);
          this.tableData.coinTube = get(resp, "payload.data.coinTube", []);
          if (this.filters.line.label === NSEWL) {
            this.tableData.manualChange.forEach((item) => {
              item.abbreviation = item.abbreviation.replace("CBSH", "BSH");
              item.abbreviation = item.abbreviation.replace("CPYL", "PYL");
              item.abbreviation = item.abbreviation.replace("CMRB", "MRB");
              item.abbreviation = item.abbreviation.replace("CBNV", "BNV");
              item.abbreviation = item.abbreviation.replace("CDBG", "DBG");
            });
            // 重新对this.tableData.manualChange按照abbreviation属性排序
            this.tableData.manualChange.sort((a, b)=> a.abbreviation > b.abbreviation ? 1 : -1)
            
            this.tableData.coinTube.forEach((item) => {
              item.abbreviation = item.abbreviation.replace("CBSH", "BSH");
              item.abbreviation = item.abbreviation.replace("CPYL", "PYL");
              item.abbreviation = item.abbreviation.replace("CMRB", "MRB");
              item.abbreviation = item.abbreviation.replace("CBNV", "BNV");
              item.abbreviation = item.abbreviation.replace("CDBG", "DBG");
            });
             // 重新对this.tableData.coinTube按照abbreviation属性排序
            this.tableData.coinTube.sort((a, b)=> a.abbreviation > b.abbreviation ? 1 : -1)
          }
          this.tableData.psm = get(resp, "payload.data.psm", []);
          this.tableData.others = get(resp, "payload.data.others", []);
          this.loadExportData();
        } else {
          this.error = resp.payload;
        }
        this.isLoading = false;    
      }
    },
    loadExportData(){
      if (!this.filters.source) 
        return [];
      let allDates = [];
      let result = [];
      let start = moment.utc(get(this.tableData, "startDate"));
      let end = moment.utc(get(this.tableData, "endDate"));
      if (!start.isValid() || !end.isValid()) return [];
      while (start.isBefore(end)) {
        allDates.push(displayUtcDate(start));
        start.add(1, "days");
      }
      this.exportData.header = ['No', 'Station', ...allDates, 'Total'];

      let data = this.tableData[this.filters.source.value];
      data.forEach((s, index) => {
        let row = {No: index+1, Station: s.stationName};
        let total = 0;
        allDates.forEach((day) => {
          const amount = this.amountOfDate(s.dailyReceipts, day)
          total += amount;
          row[day] = amount;
        });
        row.Total = total;
        result.push(row);
      });
      this.exportData.stationData = result;
    },
    amountOfDate(data = [], date) {
      let amount = 0;
      data.forEach((item) => {
        let itemDate = displayUtcDate(moment.utc(item.date));
        if(itemDate == date) {
          amount += get(item, "amount", 0);
        }
      });
      return amount;
    },
  },
};
</script>
